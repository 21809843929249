.tm-ug-version {
    position: absolute;
    left: 1rem;
    font-style: italic;
    font-size: 0.8rem;
}

.tm-ug-modal {
   background-color: #2b2b2b;
}

.tm-ug-modal .modal-content {
    background-color: #2b2b2b !important;
    color: white;
}

.tm-ug-modal .modal-content .close {
    color: white;
}
