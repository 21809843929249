/* .tm-tasklist {
} */

.tm-tasklist-options {
    /* text-align: right; */
    display: inline-block;
}

.tm-tasklist-dndc-hidden {
    display: none;
}

.tm-tasklist-dgable-hidden {
    display: none;
}