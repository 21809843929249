.tm-taskdetail-modal {
    background-color: #212529;
}

.tm-taskdetail-modal .modal-content {
    background-color: #212529 !important;
    color: white;
}

.tm-taskdetail-modal .modal-content .close {
    color: white;
}

.tm-taskdetail-metadata-block {
    right: 50px;
    position: absolute;
    bottom: 1rem;
    font-size: 1rem;
    font-style: italic;
    color: darkgrey;
}

.btn-caps .btn {
    font-variant: all-small-caps !important;
}

.btn-caps {
    font-variant: all-small-caps !important;
}

.btn-primary {
    width: 8rem;
}

.tm-taskdetail-modal-pri .btn {
    background-color: #212529;
}

.tm-taskdetail-modal-pri .active {
    background-color: grey !important;
}

.tm-task-detail-modal-save {
    margin-left: 2rem !important; 
}