.tm-taskentry-chk .input {
    margin-top: 0 !important;
}
  
.tm-taskentry-chk {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.tm-taskentry-box {
  width: 65%;
}

.tm-taskentry {
  margin-bottom: 0.1rem;
}