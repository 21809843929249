.tm-settings-version {
    position: absolute;
    left: 1em;
    font-style: italic;
    font-size: 0.8rem;
}

.tm-settings-modal {
   background-color: #2b2b2b;
}

.tm-settings-modal .modal-content {
    background-color: #2b2b2b !important;
    color: white;
}

.tm-settings-modal .modal-content .close {
    color: white;
}
