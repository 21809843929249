.tm-pomo-butts .tm-pomo-btn {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: 7rem;
}
.tm-pomo-close {
    position: absolute;
    right: 2.5rem;
}
.tm-pomo {
    margin-bottom: 1.0rem;
}