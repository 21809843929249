.tm-tlitem {
  text-align: left;
}

.tm-tlitem-titlecomp {
  text-decoration: line-through 0.14rem;
  font-style: italic;
}

.tm-tlitem-priority {
  /* font-size: 1.5rem; */
  font-size: calc(0.4rem + 2vmin);
}

.tm-tlitem-blkd {
  /* font-size: 1.35rem; */
  font-size: calc(0.2rem + 2vmin);
}

.tm-tlitem-iconbox {
  display: inline-block;
  /* min-width: 5rem; */
  /* margin-right: 0.5rem; */
  line-height: 1rem;
}

.tm-tlitem-recurs {
  /* font-size: 1.65rem; */
  font-size: calc(0.6rem + 2vmin);
}

.tm-tlitem-col {
  float: left;
}

.tm-tlitem-col-icons {
  /* min-width: 5rem; */
  width: 13%;
}

.tm-tlitem-col-text {
  /* max-width: 90%; */
  width: 87%;
}

/* Clear floats after the columns */
.tm-tlitem-row:after {
  content: "";
  display: table;
  clear: both;
}